import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import {mergeRouters} from "@/router/merge";

const routes: Array<RouteRecordRaw> = [
  ...mergeRouters,
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = <string>to.meta.cName;
  next()
})

// router.afterEach((to, from, failure) => {
//
// })


export default router
