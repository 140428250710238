// 课表
import {RouteRecordRaw} from "vue-router";

export const timetableRouterList: Array<RouteRecordRaw> = [
  {
    path: '/timetable',
    name: 'Timetable',
    component: () => import('@/views/timetable/Timetable.vue'),
    meta: {
      cName: 'xxx',
      keepAlive: false,
    },
    redirect: '/timetable/index',
    children: [
      {
        path: '/timetable/index',
        name: 'TimetableIndex',
        component: () => import('@/views/timetable/myTimetable/TimetableIndex/TimetableIndex.vue'),
        meta: {
          cName: '首页',
          keepAlive: false,
        }
      },
      {
        path: '/timetable/my',
        name: 'MyTimetable',
        component: () => import('@/views/timetable/myTimetable/MyTimetable/MyTimetable.vue'),
        meta: {
          cName: '我的课表',
          keepAlive: false,
        }
      },
      // {
      //   path: '/timetable/classtime',
      //   name: 'ClassTime',
      //   component: () => import('@/views/timetable/myTimetable/ClassTime/ClassTime.vue'),
      //   meta: {
      //     cName: '选择课次',
      //     keepAlive: false,
      //   }
      // },
      {
        path: '/timetable/classtime',
        name: 'ClassTime',
        component: () => import('@/views/timetable/myTimetable/ClassTime/ClassTime2.vue'),
        meta: {
          cName: '选择课次',
          keepAlive: false,
        }
      },
      {
        path: '/timetable/choose',
        name: 'CheckOrTeach',
        component: () => import('@/views/timetable/myTimetable/CheckOrTeach/CheckOrTeach.vue'),
        meta: {
          cName: '大卫美术教育',
          keepAlive: false,
        }
      },
      {
        path: '/timetable/check',
        name: 'CheckStudent',
        component: () => import('@/views/timetable/myTimetable/CheckStudent/CheckStudent.vue'),
        meta: {
          cName: '上课点名',
          keepAlive: false,
        }
      },
      {
        path: '/timetable/courseware',
        name: 'Courseware',
        component: () => import('@/views/timetable/myTimetable/Courseware/Courseware.vue'),
        meta: {
          cName: '播放课件',
          keepAlive: false,
        }
      },
      {
        path: '/timetable/jifen',
        name: 'JifenPage',
        component: ()=> import('@/views/timetable/myTimetable/JifenPage/JifenPage.vue'),
        meta: {
          cName: '奖励积分卡',
          keepAlive: false,
        }
      },
      {
        path: '/timetable/homework',
        name: 'Homework',
        component: ()=> import('@/views/timetable/myTimetable/Homework/Homework.vue'),
        meta: {
          cName: '上节作业讲评',
          keepAlive: false,
        }
      },
      {
        path: '/timetable/material',
        name: 'SourceMaterial',
        component: ()=> import('@/views/timetable/myTimetable/SourceMaterial/SourceMaterial.vue'),
        meta: {
          cName: '个人素材',
          keepAlive: false,
        }
      },
      {
        path: '/timetable/material/types',
        name: 'MaterialTypes',
        component: () => import('@/views/timetable/myTimetable/MaterialTypes/MaterialTypes.vue'),
        meta: {
          cName: '自定义素材',
          keepAlive: false,
        }
      },
      {
        path: '/timetable/material/aimt',
        name: 'MaterialAIMt',
        component: () => import('@/views/timetable/myTimetable/MaterialTypes/MaterialAIMt.vue'),
        meta: {
          cName: '自定义素材',
          keepAlive: false,
        }
      },
      {
        path: '/timetable/material/ais',
        name: 'MaterialAIs',
        component: () => import('@/views/timetable/myTimetable/MaterialTypes/MaterialAIs.vue'),
        meta: {
          cName: "AI素材",
          keepAlive: false,
        }
      },
      {
        path: '/timetable/league',
        name: 'LeagueTable',
        component: () => import('@/views/timetable/myTimetable/LeagueTable/LeagueTable.vue'),
        meta: {
          cName: '积分榜',
          keepAlive: false,
        }
      },
      {
        path: '/timetable/record',
        name: 'SendRecord',
        component: () => import('@/views/timetable/myTimetable/SendRecord/SendRecord.vue'),
        meta: {
          cName: '发放记录',
          keepAlive: false,
        }
      },
      {
        path: '/timetable/wind',
        name:'WindTable',
        component: ()=> import('@/views/timetable/myTimetable/WindTable/WindTable.vue'),
        meta: {
          cName: '风云榜',
          keepAlive: false,
        }
      },
      {
        path: '/timetable/weixiaomo',
        name:'weixiaomo',
        component: ()=> import('@/views/timetable/myTimetable/weixiaomo/weixiaomo.vue'),
        meta: {
          cName: '卫小墨',
          keepAlive: false,
        }
      },

    ]
  },
]
