// common页面路由
import {RouteRecordRaw} from "vue-router";
export const commonRouterList: Array<RouteRecordRaw> = [
  {
    path:'/home',
    name: 'Authority',
    component: () => import('@/views/common/Authority/Authority.vue'),
    meta: {
      cName: '授权',
      keepAlive: false,
    }
  },
  {
    path:'/login',
    name: 'Login',
    component: () => import('@/views/common/Login/Login.vue'),
    meta: {
      cName: '登录',
      keepAlive: false,
    }
  },
  // {
  //   path: '/login',
  //   name: 'Login2',
  //   component: () => import('@/views/common/Login2/Login2.vue'),
  //   meta: {
  //     cName: '登录',
  //     keepAlive: false,
  //   }
  // },
  {
    path: '/scanLogin',
    name: 'ScanLogin',
    component: () => import('@/views/common/ScanLogin/ScanLogin.vue'),
    meta: {
      cName: '扫码',
      keepAlive: false,
    }
  },
  {
    path: '/game',
    name: 'Game',
    component: () => import('@/views/common/Game/Game.vue'),
    meta: {
      cName: '小游戏',
      keepAlive: false,
    }
  }
]
