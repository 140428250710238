// common页面路由
import {RouteRecordRaw} from "vue-router";

export const conferenceRouterList: Array<RouteRecordRaw> = [
    {
        path: '/conference',
        name: 'ConferenceIndex',
        component: () => import('@/views/conference/ConferenceIndex.vue'),
        meta: {
            cName: '',
            keepAlive: false,
        }
    },
    {
        path: '/conference/class',
        name: 'ConferenceClassList',
        component: () => import('@/views/conference/ClassList/ClassList.vue'),
        meta: {
            cName: '班级选择',
            keepAlive: false,
        }
    },
    {
        path: '/conference/tot',
        name: 'ReportClass',
        component: () => import('@/views/conference/ConferenceItem/reportClass.vue'),
        meta: {
            cName: '大卫美术教育',
            keepAlive: false,
        }
    },
    {
        path: '/conference/first',
        name: 'PageFirst',
        component: () => import('@/views/conference/ConferenceItem/firstPage.vue'),
        meta: {
            cName: '大卫美术教育',
            keepAlive: false,
        }
    },
    {
        path: '/conference/first_1',
        name: 'PageVideo',
        component: () => import('@/views/conference/ConferenceItem/PageVideo.vue'),
        meta: {
            cName: '大卫美术教育',
            keepAlive: false,
        }
    },
    {
        path: '/conference/cover',
        name: 'SmallGameCover',
        component: () => import('@/views/conference/ConferenceItem/SmallGameCover.vue'),
        meta: {
            cName: '我们一起玩游戏',
            keepAlive: false,
        }
    },
    {
        path: '/conference/second',
        name: 'PageSecond',
        component: () => import('@/views/conference/ConferenceItem/twoPage.vue'),
        meta: {
            cName: '我们一起玩游戏',
            keepAlive: false,
        }
    },
    {
        path: '/conference/third',
        name: 'PageThird',
        component: () => import('@/views/conference/ConferenceItem/threePage.vue'),
        meta: {
            cName: '我们的成长',
            keepAlive: false,
        }
    },
    {
        path: '/conference/fourth',
        name: 'PageFourth',
        component: () => import('@/views/conference/ConferenceItem/fourPage.vue'),
        meta: {
            cName: '我们的学习计划',
            keepAlive: false,
        }
    },
    {
        path: '/conference/fifth',
        name: 'PageFifth',
        component: () => import('@/views/conference/ConferenceItem/fivePage.vue'),
        meta: {
            cName: '知识点学习回顾',
            keepAlive: false,
        }
    },
    {
        path: '/conference/sixth',
        name: 'PageSixth',
        component: () => import('@/views/conference/ConferenceItem/sixPage.vue'),
        meta: {
            cName: '分享小成果',
            keepAlive: false,
        }
    },
    {
        path: '/conference/parentSay',
        name: 'ParentSay',
        component: () => import('@/views/conference/ConferenceItem/ParentSay.vue'),
        meta: {
            cName: '家长寄语',
            keepAlive: false,
        }
    },
    {
        path: '/conference/seventh',
        name: 'PageSeventh',
        component: () => import('@/views/conference/ConferenceItem/sevenPage.vue'),
        meta: {
            cName: '大卫美术教育',
            keepAlive: false,
        }
    },
    {
        path: '/conference/eighth',
        name: 'PageEighth',
        component: () => import('@/views/conference/ConferenceItem/eightPage.vue'),
        meta: {
            cName: '到课率',
            keepAlive: false,
        }
    },
    {
        path: '/conference/ninth',
        name: 'PageNinth',
        component: () => import('@/views/conference/ConferenceItem/ninePage.vue'),
        meta: {
            cName: '学生成长回顾',
            keepAlive: false,
        }
    },
    {
        path: '/conference/tenth',
        name: 'PageTenth',
        component: () => import('@/views/conference/ConferenceItem/tenPage.vue'),
        meta: {
            cName: '颁发小奖励',
            keepAlive: false,
        }
    },
    {
        path: '/conference/eleventh',
        name: 'PageEleventh',
        component: () => import('@/views/conference/ConferenceItem/elevenPage.vue'),
        meta: {
            cName: '全勤之星',
            keepAlive: false,
        }
    },
    {
        path: '/conference/twelfth',
        name: 'PageTwelfth',
        component: () => import('@/views/conference/ConferenceItem/twelvePage.vue'),
        meta: {
            cName: '学习维度之星',
            keepAlive: false,
        }
    },
    {
        path: '/conference/thirteenth',
        name: 'PageThirteenth',
        component: () => import('@/views/conference/ConferenceItem/thirteenPage.vue'),
        meta: {
            cName: '参赛颁奖',
            keepAlive: false,
        }
    },
    {
        path: '/conference/fourteenth',
        name: 'PageFourteenth',
        component: () => import('@/views/conference/ConferenceItem/fourteenPage.vue'),
        meta: {
            cName: '我们的学习规划',
            keepAlive: false,
        }
    },
    {
        path: '/conference/fifteenth',
        name: 'PageFifteenth',
        component: () => import('@/views/conference/ConferenceItem/fifteenPage.vue'),
        meta: {
            cName: '升班建议',
            keepAlive: false,
        }
    },
    {
        path: '/conference/sixteenth',
        name: 'PageSixteenth',
        component: () => import('@/views/conference/ConferenceItem/sixteenPage.vue'),
        meta: {
            cName: '核心课程',
            keepAlive: false,
        }
    },
    {
        path: '/conference/seventeenth',
        name: 'PageSeventeenth',
        component: () => import('@/views/conference/ConferenceItem/seventeenPage.vue'),
        meta: {
            cName: '特色课程',
            keepAlive: false,
        }
    },
    {
        path: '/conference/eighteenth',
        name: 'PageEighteenth',
        component: () => import('@/views/conference/ConferenceItem/eighteenPage.vue'),
        meta: {
            cName: '考级',
            keepAlive: false,
        }
    },
    {
        path: '/conference/nineteenth',
        name: 'PageNineteenth',
        component: () => import('@/views/conference/ConferenceItem/nineteenPage.vue'),
        meta: {
            cName: '艺术实践',
            keepAlive: false,
        }
    },
    {
        path: '/conference/twentieth',
        name: 'PageTwentieth',
        component: () => import('@/views/conference/ConferenceItem/twentyPage.vue'),
        meta: {
            cName: '618优惠',
            keepAlive: false,
        }
    },
    {
        path: '/conference/twentieth-firth',
        name: 'PageTwentiethFirth',
        component: () => import('@/views/conference/ConferenceItem/twentyOnePage.vue'),
        meta: {
            cName: '大卫美术教育',
            keepAlive: false,
        }
    },
    {
        path: '/studyBlessing',
        name: 'studyBlessing',
        component: () => import('@/views/conference/ConferenceItem/studyBlessing.vue'),
        meta: {
            cName: '成果展示',
            keepAlive: false,
        }
    },
    {
        path: '/howAreWeLearning',
        name: 'howAreWeLearning',
        component: () => import('@/views/conference/ConferenceItem/howAreWeLearning.vue'),
        meta: {
            cName: '亲子绘画时刻',
            keepAlive: false,
        }
    },
    {
        path: '/frameDesigner',
        name: 'frameDesigner',
        component: () => import('@/views/conference/ConferenceItem/frameDesigner.vue'),
        meta: {
            cName: '相框设计师',
            keepAlive: false,
        }
    },
    {
        path: '/decorate',
        name: 'decorate',
        component: () => import('@/views/conference/ConferenceItem/decorate.vue'),
        meta: {
            cName: '相框设计师',
            keepAlive: false,
        }
    },
    {
        path: '/decorateStrategy',
        name: 'decorateStrategy',
        component: () => import('@/views/conference/ConferenceItem/decorateStrategy.vue'),
        meta: {
            cName: '相框设计师',
            keepAlive: false,
        }
    },
    {
        path: '/contentImagination',
        name: 'contentImagination',
        component: () => import('@/views/conference/ConferenceItem/contentImagination.vue'),
        meta: {
            cName: '相框设计师',
            keepAlive: false,
        }
    },
    {
        path: '/photoContent',
        name: 'photoContent',
        component: () => import('@/views/conference/ConferenceItem/photoContent.vue'),
        meta: {
            cName: '相框设计师',
            keepAlive: false,
        }
    },
    {
        path: '/cutePets',
        name: 'cutePets',
        component: () => import('@/views/conference/ConferenceItem/cutePets.vue'),
        meta: {
            cName: '相框设计师',
            keepAlive: false,
        }
    },
    {
        path: '/reportClassV1',
        name: 'reportClassV1',
        component: () => import('@/views/conference/reportClassV1/reportClassV1.vue'),
        meta: {
            cName: '改造石膏娃娃',
            keepAlive: false,
        }
    },

    {
        path: '/videoPresentation',
        name: 'videoPresentation',
        component: () => import('@/views/conference/reportClassV1/videoPresentation.vue'),
        meta: {
            cName: '相框设计师',
            keepAlive: false,
        }
    },
    {
        path: '/videoPresentation1',
        name: 'videoPresentation1',
        component: () => import('@/views/conference/reportClassV1/videoPresentation1.vue'),
        meta: {
            cName: '博物馆视频',
            keepAlive: false,
        }
    },
    {
        path: '/semesterHighlights',
        name: 'semesterHighlights',
        component: () => import('@/views/conference/reportClassV1/semesterHighlights.vue'),
        meta: {
            cName: '学期亮点',
            keepAlive: false,
        }
    },
    {
        path: '/theImportanceOfAestheticEducation',
        name: 'theImportanceOfAestheticEducation',
        component: () => import('@/views/conference/reportClassV1/theImportanceOfAestheticEducation.vue'),
        meta: {
            cName: '美育的重要性',
            keepAlive: false,
        }
    },
    {
        path: '/reviewOfStudentGrowth',
        name: 'reviewOfStudentGrowth',
        component: () => import('@/views/conference/ConferenceItem/reviewOfStudentGrowth.vue'),
        meta: {
            cName: '学生成长回顾',
            keepAlive: false,
        }
    },
    {
        path: '/groupPhoto',
        name: 'groupPhoto',
        component: () => import('@/views/conference/ConferenceItem/groupPhoto.vue'),
        meta: {
            cName: '合影留恋',
            keepAlive: false,
        }
    },
    {
        path: '/special2023',
        name: 'special2023',
        component: () => import('@/views/conference/ConferenceItem/special2023.vue'),
        meta: {
            cName: '我们未来学什么',
            keepAlive: false,
        }
    }

]
